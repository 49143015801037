<template>
	<div class="pb-20">
		<nav class="fixed top-0 z-50 w-full flex justify-center py-2 bg-black border-b-2 border-gold">
			<div class="mx-4 lg:mx-[5rem] w-full sm:px-0 flex justify-between">
				<div
					@click="toggleNavbar()"
					class="w-1/4 text-white flex items-center"
				>
					<div class="flex flex-col transition duration-300 hover:scale-105">
						<div
							class="h-[0.1rem] my-1 rounded-full duration-300 bg-white trasition duration-700 ease-out"
							:class="navbarOpen ? 'rotate-45 translate-y-2 w-8' : 'w-10'"
						></div>
						<div
							class="h-[0.1rem] my-1 rounded-full duration-300 bg-white trasition duration-700 ease-out"
							:class="navbarOpen ? '-rotate-45 w-8 -translate-y-0.5' : 'w-8'"
						></div>
					</div>
					<span class="uppercase ml-3 transition duration-300 hover:scale-105">menu</span>
				</div>
				<div class="w-1/4flex justify-center pointer-events-none">
					<div class="pointer-events-auto">
						<router-link to="/">
							<img
								class="mt-2 h-16 w-auto"
								src="../assets/img/logo/logo.svg"
								alt=""
							/>
						</router-link>
					</div>
				</div>
				<div class="w-1/4 items-center justify-end hidden md:flex">
					<Basket />
					<button
						class="px-6 h-10 border-2 border-gold text-sm rounded-md max-w-[24ch] truncate text-white"
						type="button"
						@click="initConnect()"
					>
						{{ this.web3 && this.web3.wallet ? this.web3.wallet : "Connect" }}
					</button>
				</div>
			</div>
		</nav>
		<div>
			<div
				@click="toggleNavbar()"
				class="z-10 fixed inset-0"
				:class="navbarOpen ? '' : 'hidden'"
			></div>
			<div
				class="bg-black py-1.5 w-full h-screen max-w-xl fixed z-[49] top-0 left-0 transition duration-700 ease-out"
				:class="navbarOpen ? 'translate-x-0' : '-translate-x-full'"
			>
				<div class="pl-[5rem] py-[5rem]">
					<div class="">
						<div class="mt-10 space-y-5 text-white">
							<div
								@click="redirectTo('/')"
								class="uppercase text-3xl cursor-pointer"
							>
								Home
							</div>
							<div
								@click="redirectTo('/roadmap')"
								class="uppercase text-3xl cursor-pointer"
							>
								Roadmap
							</div>
							<div
								@click="redirectTo('/museums')"
								class="uppercase text-3xl cursor-pointer"
							>
								Museums
							</div>
							<div
								@click="redirectTo('/nft')"
								class="uppercase text-3xl cursor-pointer"
							>
								About NFT
							</div>
							<div
								@click="redirectTo('/about-watch')"
								class="uppercase text-3xl cursor-pointer"
							>
								Watch details
							</div>
							<div
								@click="redirectTo('/marcel-van-luit')"
								class="uppercase text-3xl cursor-pointer"
							>
								Marcel van luit
							</div>
							<div
								@click="redirectTo('/construct-watch')"
								class="uppercase text-3xl cursor-pointer"
							>
								Construct Watch
							</div>
						</div>
					</div>

					<div class="absolute bottom-5 text-white flex">
						<a
							href="https://discord.com/invite/nR2DYfBEsQ"
							target="_blank"
							rel="noopener noreferrer"
						>
							<button
								class="stroke-white items-center justify-center align-center outline-none focus:outline-none mr-2"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="icon icon-tabler icon-tabler-brand-twitter w-8 w-8"
									viewBox="0 0 24 24"
									stroke-width="1"
									fill="none"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path
										stroke="none"
										d="M0 0h24v24H0z"
										fill="none"
									/>
									<path
										d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z"
									/>
								</svg>
							</button>
						</a>

						<a
							href="https://twitter.com/Jaques_Sermand"
							target="_blank"
							rel="noopener noreferrer"
						>
							<button
								class="stroke-white items-center justify-center align-center outline-none focus:outline-none mr-2"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="icon icon-tabler icon-tabler-brand-discord h-8 w-8"
									viewBox="0 0 24 24"
									stroke-width="1"
									fill="none"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path
										stroke="none"
										d="M0 0h24v24H0z"
										fill="none"
									/>
									<circle
										cx="9"
										cy="12"
										r="1"
									/>
									<circle
										cx="15"
										cy="12"
										r="1"
									/>
									<path d="M7.5 7.5c3.5 -1 5.5 -1 9 0" />
									<path d="M7 16.5c3.5 1 6.5 1 10 0" />
									<path
										d="M15.5 17c0 1 1.5 3 2 3c1.5 0 2.833 -1.667 3.5 -3c.667 -1.667 .5 -5.833 -1.5 -11.5c-1.457 -1.015 -3 -1.34 -4.5 -1.5l-1 2.5"
									/>
									<path
										d="M8.5 17c0 1 -1.356 3 -1.832 3c-1.429 0 -2.698 -1.667 -3.333 -3c-.635 -1.667 -.476 -5.833 1.428 -11.5c1.388 -1.015 2.782 -1.34 4.237 -1.5l1 2.5"
									/>
								</svg></button
						></a>

						<a
							href="http://"
							target="_blank"
							rel="noopener noreferrer"
						>
							<button
								class="stroke-white items-center justify-center align-center outline-none focus:outline-none mr-2"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="icon icon-tabler icon-tabler-brand-instagram h-8 w-8"
									viewBox="0 0 24 24"
									stroke-width="1"
									fill="none"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path
										stroke="none"
										d="M0 0h24v24H0z"
										fill="none"
									/>
									<rect
										x="4"
										y="4"
										width="16"
										height="16"
										rx="4"
									/>
									<circle
										cx="12"
										cy="12"
										r="3"
									/>
									<line
										x1="16.5"
										y1="7.5"
										x2="16.5"
										y2="7.501"
									/>
								</svg>
							</button>
						</a>

						<a
							href="http://"
							target="_blank"
							rel="noopener noreferrer"
						>
							<button
								class="stroke-white items-center justify-center align-center outline-none focus:outline-none mr-2"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="icon icon-tabler icon-tabler-brand-youtube h-8 w-8"
									viewBox="0 0 24 24"
									stroke-width="1"
									fill="none"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path
										stroke="none"
										d="M0 0h24v24H0z"
										fill="none"
									/>
									<rect
										x="3"
										y="5"
										width="18"
										height="14"
										rx="4"
									/>
									<path d="M10 9l5 3l-5 3z" />
								</svg>
							</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// libs
import * as _state from "../lib/state";
import * as _web3 from "../lib/web3";

// components
import Basket from "./Basket.vue";

export default {
	name: "NavbarIndex",
	components: {
		Basket,
	},
	data() {
		return {
			navbarOpen: false,
		};
	},
	async mounted() {},
	methods: {
		toggleNavbar() {
			this.navbarOpen = !this.navbarOpen;
		},
		redirectTo(route) {
			this.toggleNavbar();
			this.$router.push(route);
		},
		async initConnect() {
			await _web3.connect();
		},
	},
	computed: {
		web3() {
			return _state.get("web3/getWeb3");
		},
	},
};
</script>
