<template>
	<status-booting v-if="booted === false && cssLoaded === false" />
	<div v-else>
		<div>
			<navbar-index />

			<router-view v-slot="{ Component }">
				<component :is="Component" />
			</router-view>

			<Footer />
			<NoWalletPopupVue />
			<AddedItemPopup />
			<HelpPopup />
		</div>
	</div>
</template>

<script>
// libs
import * as _web3 from "../lib/web3";
import * as _state from "../lib/state";
import * as _transaction from "../lib/transaction";

// components
import NavbarIndex from "../components/NavbarIndex.vue";
import Footer from "../components/Footer.vue";
import NoWalletPopupVue from "../components/NoWalletPopup.vue";
import AddedItemPopup from "@/components/AddedItemPopup.vue";
import HelpPopup from "@/components/HelpPopup.vue";

export default {
	components: {
    NavbarIndex,
    Footer,
    NoWalletPopupVue,
    AddedItemPopup,
    HelpPopup
},
	name: "BootLoader",
	async mounted() {
		await this.boot();
	},

	data() {
		return {
			booted: false,
		};
	},

	methods: {
		async boot() {
			this.validate()
			_web3.init();
			_transaction.init();
			this.initAffiliate();

			this.booted = true;
		},

		validate() {
			let host = window.location.host;

			if (host !== 'www.jaquessermand.ch' && host !== 'jaquessermand.ch' && host !== 'localhost:8081' && host !== 'staging.jaquessermand.ch'){
				window.location.href = 'https://www.jaquessermand.ch'
			}
		},

		async initAffiliate() {
			const transaction = _state.get("transaction/getTransaction");

			let params = this.$route.query;

			if (params.id) {
				_transaction.validateAffiliateKey(params.id);

				this.$router.replace({ id: null });
			} else if (
				transaction.affiliate !== false &&
				Date.now() - transaction.affiliateAddedOn > 24 * 60 * 60 * 1000
			) {
				_transaction.resetAffiliate();
			}
		},
	},
};
</script>
