import { createApp } from "vue";
import { createI18n } from "vue-i18n";

import App from "./App.vue";
import router from "./router";
import "./main.css";
import "./assets/scss/style.scss";
import axios from "axios";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import { getNumberFormats } from "./lib/i18n";

import { createHead } from "@vueuse/head";
const head = createHead();

let numberFormats = getNumberFormats();

const i18n = createI18n({
	legacy: false,
	locale: "nl",
	numberFormats,
	warnHtmlMessage: false, // disable warning HTML in message
});

axios.defaults.baseURL = "https://staging-api.jaquessermand.ch/v1/";
// axios.defaults.baseURL = "http://localhost:8000/v1/";
axios.defaults.headers.common["Domain"] = window.location.host;

createApp(App).use(router).use(i18n).use(VCalendar).use(head).mount("#app");
